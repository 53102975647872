import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react';
import fetch from 'cross-fetch';
import Card from '../components/Card';
import ModalChart from '../components/ModalChart';
import CardLoading from '../components/CardLoading';


const CardList = ({pais, dataNow}) =>  {

    //const [dataNow, setDataNow] = useState(null);
    const [companyActual, setCompanyActual] = useState({company: null, type: null});
    const [currentSort, setCurrentSort] = useState('default');
    const [currentSortType, setCurrentSortType] = useState('compra');
    const [mobileType, setMobileType] = useState({compra: 'd-block', venta: 'd-none', bg_compra: 'bg-primary text-white', bg_venta: 'bg-white'});
    const [dataRefresh, setDataRefresh] = useState(false);


    const onSortChange = (value) => (key) => {
        let initial
        if (currentSort === 'down') initial = 'up';
        else if (currentSort === 'up') initial = 'down';
        else if (currentSort === 'default') initial = 'down';
        
        if (value === 'compra'){
          setCurrentSortType('compra');
          setMobileType({compra: 'd-block', venta: 'd-none', bg_compra: 'bg-primary text-white', bg_venta: 'bg-white'})
        } 
        else{
          setCurrentSortType('venta');
          setMobileType({compra: 'd-none', venta: 'd-block', bg_compra: 'bg-white', bg_venta: 'bg-primary  text-white'})
        }
        setCurrentSort(initial)
    };

    // COMPANY
    const onChangeCompany = (company, type) => (key) => {
        setCompanyActual({company: company, type: type});
    };

    function fnt_dateTo() {
      var options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric'};
      if (dataNow != null) {
        return new Intl.DateTimeFormat('es-ES', options).format(new Date(dataNow.maxmin.compra.fecha_hora_cotizacion))
      }
    }

    //useEffect(() => {
    //    setDataNow(null);
    //    const fetchCurrency = async () => {
    //      fetch(ENV.API_URL_NOW+pais.toUpperCase(), ENV.API_HEADERS_GET)
    //      .then(async response => await response.json())
    //      .then(result => {
    //        if (result.length === 0 || Object.keys(result).length === 0) {
    //          setDataNow(null);
    //        } else {
    //          setDataNow(result);
    //        }
    //      })
    //      .catch(error => console.error(error));
    //    };
    //    fetchCurrency();
    //}, [dataRefresh]);

  return (
    <>
        <section className="py-5 bg-light">
            <div className="container text-center mb-5">
                <h2 className="h2 display-5 text-dark">
                  Compara y encuentra el mejor <strong className="text-secondary">tipo de cambio</strong>
                </h2>
                <p className="fs-5 lead">
                  Mostramos sólo las casas de cambio registrados en la Superintendencia de Banca y Seguros del Perú
                </p>
            </div>
            <div className="container bg-white rounded border p-2 px-md-5 pb-md-5">
                <div className="row">
                  <div className="col-12">
                    <p className="fs-6 text-muted float-end pt-2">Obtenido el {fnt_dateTo()}</p>
                  </div>
                  <div className="col-12 sticky-top">
                    <div className="row border-0 m-0 text-center fs-6">
                      <div className="col-4 col-md-2 p-0">
                        <button className="btn btn-primary text-white py-2 border-0 rounded-0 w-100">
                          Empresa
                        </button>
                      </div>
                      <div className="col-4 col-md-4 p-0">
                        <button className="btn btn-primary text-white py-2 border-0 rounded-0 w-100" onClick={onSortChange('compra')}>
                          Compra <i className={currentSortType==='compra' ? ENV.FILTER_OPTIONS[currentSort].class: ENV.FILTER_OPTIONS['default'].class }/>
                        </button>
                      </div> 
                      <div className="col-4 col-md-4 p-0">
                        <button className="btn btn-primary text-white py-2 border-0 rounded-0 w-100" onClick={onSortChange('venta')}>
                          Venta <i className={currentSortType==='venta' ? ENV.FILTER_OPTIONS[currentSort].class: ENV.FILTER_OPTIONS['default'].class }/>
                        </button>
                      </div> 
                      <div className="col-md-2 p-0 d-none d-sm-block">
                        <button className="btn btn-primary text-white py-2 border-0 rounded-0 w-100">
                          Página web
                        </button>
                      </div> 
                    </div>
                  </div>
                  {
                    dataNow == null ? 
                    <CardLoading mobileType={mobileType}/>
                    : 
                    dataNow.now.sort(ENV.FILTER_OPTIONS[currentSort].fn[currentSortType]).map((value, key) => {
                        var color_card = key%2 !== 0 ? 'bg-white' : 'bg-white'
                        return (
                          <div key={key}>
                            <Card 
                                data={value} 
                                onChangeCompany={onChangeCompany} 
                                color_card={color_card} 
                                mobileType={mobileType}
                            />
                          </div>
                        )
                    })
                  }
                </div>
            </div>
        </section>
        <ModalChart
          pais={pais} 
          companyActual={companyActual} 
        />
    </>
  )

}

export default CardList