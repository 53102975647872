import * as ENV from './Config.js';
import { Redirect, withRouter, useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Cuenta from '../pages/Cuenta'
import Alert from './Alert'
import ButtonSubmit from './ButtonSubmit'
import { setToken, setUsername, setEmail } from './Utils/Common'

const Login = ({pais}) =>  {

    let history = useHistory();

    const [correo, setCorreo] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
      event.preventDefault();
      setLoading(true);
      if (correo.length > 0 & contrasena.length > 0) {
        ENV.API_HEADERS_POST.body = JSON.stringify([{
          cod_pais:pais.toUpperCase(), 
          correo: correo, 
          contrasena:contrasena
        }]);
        fetch(ENV.API_URL_LOGIN, ENV.API_HEADERS_POST)
        .then(async response => await response.json())
        .then(result => {
          setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
          setLoading(false);
          if (result.correo) {
            setToken(result.access_token);
            setUsername(result.nombres);
            setEmail(result.correo);
            history.push("/pe/cuenta/");
          }
        })
        .catch(error => {
          setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
          setLoading(false);
        });
      } else {
        setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
        setLoading(false);
      }
    }

    return (
        <section className="pb-3 pb-md-5 px-3 p-md-0 bg-light">
            <div className="container pt-5">
              <div className="row">
                <div className="col-12 col-md-5 mx-auto">
                  <div className="card p-md-4">
                    <div className="card-body">
                      <h3 className="card-title text-center">Iniciar sesión</h3>
                      <p class="fs-sm text-muted text-center">
                        Inicie sesión utilizando el correo electrónico y la contraseña proporcionados durante el registro.
                      </p>
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-12">
                                {
                                  message &&
                                  <Alert message={message} setMessage={setMessage}/>
                                }
                                <div className="mb-3 row">
                                    <div className="col-12 my-2">
                                        <div className="form-floating">
                                          <input type="email" className="form-control" value={correo} onChange={(e) => setCorreo(e.target.value)} required/>
                                          <label>Correo</label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="form-floating">
                                          <input type="password" className="form-control" value={contrasena} onChange={(e) => setContrasena(e.target.value)} required/>
                                          <label>Contraseña</label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                      <div class="d-flex">
                                        <div class="me-auto"></div>
                                        <div class="float-end">
                                          <a className="btn btn-link" href={"/"+pais+"/olvidecontrasena"}>
                                            ¿Ha olvidado su contraseña?
                                          </a>  
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2">
                                  <ButtonSubmit value={'Iniciar sesión'} loading={loading}/>
                                  <p class="fs-sm pt-4 mb-0">
                                    ¿No tienes una cuenta? 
                                    <a href={"/"+pais+"/registro"} class="text-decoration-none"> Registrate</a>
                                  </p>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
    )
}
export default Login