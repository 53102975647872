import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'

const Subscribe = ({pais}) =>  {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);

    const handleChange = (event) => {
      setEmail(event.target.value);
    }

    const handleCloseMessage = (event) => {
        setEmail('');
        setMessage(null);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email !== null & email !== '') {

            ENV.API_HEADERS_POST.body = JSON.stringify([{
                cod_pais: pais.toUpperCase(), 
                correo: email
            }]);
            fetch(ENV.API_URL_SUSCRIBE, ENV.API_HEADERS_POST)
            .then(async response => await response.json())
            .then(result => {
                setMessage(['alert-success','Suscripción correcta','se ha registrado correctamente tu correo']);
            })
            .catch(error => {
                setMessage(['alert-danger','Surgio un error','por favor intente suscribirte más tarde con tu correo']);
            });
        }
        
    }

    return (
    <>
        <section className="bg-white pt-5">
            <div className="container">
                <div className="text-center">
                    <h2 className="h2 display-5 text-dark">
                      Suscribete <strong className="text-secondary">grátis</strong>
                    </h2>
                    <p className="fs-5 lead my-0 pb-2">
                        Suscríbete con tu correo para recibir notificaciones, actualizaciones e información sobre el mejor tipo de cambio.
                    </p>
                </div>
            </div>
        </section>
        <section className="py-2 bg-transparent sticky-bottom">
            <div className="container text-center">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <form className="row g-2 align-items-center justify-content-center" onSubmit={handleSubmit}>
                            <div className="col-10 col-md-6">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Suscribete con tu correo" required value={email} onChange={handleChange}/>
                                    <button type="submit" className="btn btn-primary d-none d-sm-block" atr-html-gtm-id="suscribirse">
                                        <i className="bi bi-envelope me-0 me-md-2"></i>
                                        Suscribirme
                                    </button>
                                    <button type="submit" className="btn btn-primary d-block d-sm-none">
                                        <i className="bi bi-envelope me-0 me-md-2"></i>
                                    </button>
                                </div>
                            </div>
                            {
                                message &&
                                <div className={"alert "+message[0]+" alert-dismissible fade show"} role="alert">
                                  <strong>{message[1]}</strong> {message[2]} {email}
                                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseMessage}></button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section className="pb-3 pb-5 bg-white">

        </section>
    </>
    )
}
export default Subscribe