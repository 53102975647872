import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'
import FormRegister from '../components/FormRegister';
import Alert from './Alert'
import ButtonSubmit from './ButtonSubmit'

const Register = ({pais}) =>  {

    const [correo, setCorreo] = useState("");
    const [nombres, setNombres] = useState("");
    const [apellido_paterno, setApellido_paterno] = useState("");
    const [apellido_materno, setApellido_materno] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [telefono, setTelefono] = useState("");

    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = ({correo, nombres, apellido_paterno, apellido_materno, contrasena, telefono}) => (event) => {
        event.preventDefault();
        if (correo.length > 0 & contrasena.length > 0) {
          setLoading(true);
          ENV.API_HEADERS_POST.body = JSON.stringify([{
            cod_pais: pais.toUpperCase(),
            correo: correo,
            nombres: nombres,
            apellido_paterno: apellido_paterno,
            apellido_materno: apellido_materno,
            contrasena: contrasena,
            telefono: telefono
          }]);
          fetch(ENV.API_URL_REGISTER, ENV.API_HEADERS_POST)
          .then(async response => await response.json())
          .then(result => {
            setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
            setLoading(false);
            if (result.SP_STATUS === 'OK') {
              setNombres("");
              setCorreo("");
              setContrasena("");
            }
          })
          .catch(error => {
            setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
            setLoading(false);
          });
        } else {
          setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
          setLoading(false);
        }
    }

    return (
        <section className="pb-3 pb-md-5 px-3 p-md-0 bg-light">
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 col-md-5 mx-auto">
                <div className="card px-md-4 py-md-4">
                  <div className="card-body">
                    <h3 className="card-title text-center">Nuevo Registro</h3>
                    <p class="fs-sm text-muted text-center">
                      Registrate utilizando tu correo electrónico y una contraseña segura.
                    </p>
                    <form className="g-3" onSubmit={handleSubmit({correo, nombres, apellido_paterno, apellido_materno, contrasena, telefono})}>
                      {
                        message &&
                        <Alert message={message} setMessage={setMessage}/>
                      }
                      <div className="mb-3 row">
                        <div className="col-12 col-md-12 my-2">
                            <div className="form-floating">
                              <input type="text" className="form-control" value={nombres} onChange={(e) => setNombres(e.target.value)}/>
                              <label>Nombres</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 my-2">
                            <div className="form-floating">
                              <input type="email" className="form-control" value={correo} onChange={(e) => setCorreo(e.target.value)} required/>
                              <label>Correo</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 my-2">
                            <div className="form-floating">
                              <input type="password" className="form-control" value={contrasena} onChange={(e) => setContrasena(e.target.value)} required/>
                              <label>Contraseña</label>
                            </div>
                        </div>
                        <div className="d-grid mt-3">
                          <ButtonSubmit value={'Registrarme'} loading={loading}/>
                          <p class="fs-sm pt-4 mb-0">
                            ¿Ya tienes una cuenta? 
                            <a href={"/"+pais+"/ingresar"} class="text-decoration-none"> Inicia sesión</a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default Register