import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import React, { useState, useEffect, Component } from 'react'
import { getToken } from './Utils/Common'
import Home from '../pages/Home'
import Pais from '../pages/Pais'
import NotFound from '../pages/NotFound'
import Terminos from '../pages/Terminos'
import Privacidad from '../pages/Privacidad'
import Preguntas from '../pages/Preguntas'
import Registro from '../pages/Registro'
import Cuenta from '../pages/Cuenta'
import Ingresar from '../pages/Ingresar'
import RestablecerContrasena from '../pages/RestablecerContrasena'
import OlvideContrasena from '../pages/OlvideContrasena'
import ActivarCuenta from '../pages/ActivarCuenta'
import VisitarPagina from '../pages/VisitarPagina'


function App (){

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/pe"/>
                </Route>
                <Route exact path="/pe">
                    <Pais id={'pe'}/>
                </Route>
                <Route exact path="/pe/terminos">
                    <Terminos id={'pe'}/>
                </Route>
                <Route exact path="/pe/privacidad">
                    <Privacidad id={'pe'}/>
                </Route>
                <Route exact path="/pe/preguntas">
                    <Preguntas id={'pe'}/>
                </Route>
                <Route exact path="/pe/registro">
                    {getToken() ? <Cuenta id={'pe'}/> : <Registro id={'pe'}/>}
                </Route>
                <Route exact path="/pe/restablecercontrasena/:tokenreset">
                    <RestablecerContrasena id={'pe'}/>
                </Route>
                <Route exact path="/pe/activarcuenta/:tokenactivate">
                    <ActivarCuenta id={'pe'}/>
                </Route>
                <Route exact path="/pe/olvidecontrasena">
                    <OlvideContrasena id={'pe'}/>
                </Route>
                <Route exact path="/pe/cuenta">
                    {getToken() ? <Cuenta id={'pe'}/> : <Redirect to="/pe" />}
                </Route>
                <Route exact path="/pe/ingresar">
                    {getToken() ? <Redirect to="/pe/cuenta" /> : <Ingresar id={'pe'}/>}
                </Route>
                <Route exact path="/pe/visitar/:url/:tracking">
                    <VisitarPagina/>
                </Route>
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    )
}

export default App