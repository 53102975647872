import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react';
import fetch from 'cross-fetch';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const ModalChart = ({pais, companyActual}) => {

    const [days, setDays] = useState(0);
    const [dataChart, setDataChart] = useState(null);
    const [dataCompany, setDataCompany] = useState(null);

    //Close modal
    function close_modal() {
      setDataChart([])
      setDataCompany([])
    }

    //CHART
    const handleChangeChart = (event) => {
      setDataChart([])
      setDays(event.target.value)
    }

    // De forma similar a componentDidMount y componentDidUpdate
    useEffect(() => {
        setDataChart([])
        const fetchCurrencyChart = async () => {
          fetch(ENV.API_URL_CHARTHIST+pais+'/'+days+'/'+companyActual.company.cod_empresa, ENV.API_HEADERS_GET)
          .then(async response => await response.json())
          .then(result => {
            if (result.length === 0 || Object.keys(result).length === 0) {
              setDataChart(null); 
              setDataCompany(null);
            } else {
              setDataChart(result); 
              setDataCompany(result.empresa[0]);
            }
          })
          .catch(error => console.error(error));
        };
        if (companyActual.company) {
          fetchCurrencyChart();
        }
    }, [days, companyActual]);
    

    var dataLine = {};
    var options = {};
    if (dataChart !== null) {
      dataLine = {
          //labels: [],
          datasets: [
            {
              label: 'Tipo de cambio',
              data: companyActual.type === 'compra' ? dataChart.data_compra : dataChart.data_venta,
              fill: true,
              backgroundColor: 'rgb(9, 72, 179, 0.2)',
              borderColor: 'rgba(9, 72, 179, 0.9)',
              borderWidth: 2,
              lineTension: 0,
              radius: 0,
              stepped: false,
            },
          ],
        };

      options = {
        animation: {
            duration: 0
        },
        //responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false
        },
        plugins: {
          legend: {
              display: false
          }
        },
        scales: {
          y:{
            //suggestedMin: 4.02,
          },
          x:{
            type: 'time',
            time:{
              unit: dataChart.time_unit
            }
          }
        }
      };

    }
    
    return (
      <>
      {
        dataChart &&
          <div className="modal fade modal-fullscreen" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Gráfico histórico <span className="badge bg-opacity-10 text-primary bg-primary">{companyActual.type}</span></h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={close_modal}></button>
                </div>
                <div className="modal-body">
                  <div className="row my-1 my-md-2">
                    <div className="col-12 col-md-6 my-2 my-md-0 text-center">
                      <div className="row">
                        <div className="col-12 my-auto align-items-center">
                          <img src={ companyActual.company ? '/empresas/pe/'+companyActual.company.nombre_logo : '' } className="img-fluid"/>
                        </div>
                        <div className="col-12">
                          <small className="modal-title">
                          
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 my-2 my-md-0 text-center">
                      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio" value="0" id="btnradio0" autoComplete="off" defaultChecked onChange={handleChangeChart}/>
                        <label className="btn btn-sm btn-outline-primary rounded m-1 m-md-2" htmlFor="btnradio0">Hoy</label>

                        <input type="radio" className="btn-check" name="btnradio" value="1" id="btnradio1" autoComplete="off" onChange={handleChangeChart}/>
                        <label className="btn btn-sm btn-outline-primary rounded m-1 m-md-2" htmlFor="btnradio1">1D</label>

                        <input type="radio" className="btn-check" name="btnradio" value="7" id="btnradio2" autoComplete="off" onChange={handleChangeChart}/>
                        <label className="btn btn-sm btn-outline-primary rounded m-1 m-md-2" htmlFor="btnradio2">7D</label>

                        <input type="radio" className="btn-check" name="btnradio" value="15" id="btnradio3" autoComplete="off" onChange={handleChangeChart}/>
                        <label className="btn btn-sm btn-outline-primary rounded m-1 m-md-2" htmlFor="btnradio3">15D</label>

                        <input type="radio" className="btn-check" name="btnradio" value="30" id="btnradio4" autoComplete="off" onChange={handleChangeChart}/>
                        <label className="btn btn-sm btn-outline-primary rounded m-1 m-md-2" htmlFor="btnradio4">1M</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 my-auto align-items-center">
                      <div style={{height:'50vh' , width:'auto'}}>
                        <Line data={dataLine} options={options}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={close_modal}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
      }
      </>
    )

}

export default ModalChart