import * as ENV from './Config.js';
import React, { useEffect } from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
//import ReactTooltip from 'react-tooltip/dist/index.es.js';


const Card = ({data,onChangeCompany,color_card,mobileType}) => {

    const empresa = data.empresa
    let compra_history = data.tracking_hist.map((val,idx) => val.dolar_compra);
    let venta_history = data.tracking_hist.map((val,idx) => val.dolar_venta);
    var options = {day: 'numeric', month: 'short', hour: 'numeric', hour12: true, minute: 'numeric'};

    useEffect(() => {
      // init tooltip
      Array.from(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).forEach(tooltipNode => new Tooltip(tooltipNode));
    }, []);

    return (
        <div className="col-12" key={data.cod_empresa}>
                <div className={'card border-0 rounded-0 border-bottom '+color_card }>
                  <div className="card-body py-2 px-1 p-md-2">
                      <div className="row">
                      
                        <div className="col-4 col-md-2 my-auto align-items-center text-center">
                          <img src={'/empresas/pe/'+empresa.nombre_logo} className="img-fluid"/>
                        </div>

                        <div className={"col-3 col-md-4"}>
                          <CardChartLine 
                            dolar={data.dolar_compra} 
                            flag={data.flag_variacion_compra} 
                            variacion={data.porc_variacion_compra} 
                            empresa={empresa} 
                            dataLine={compra_history}
                            date={data.fecha_hora_cotizacion} 
                            onChangeCompany={onChangeCompany} 
                            type={'compra'} 
                          />
                        </div>

                        <div className={"col-3 col-md-4"}>
                          <CardChartLine 
                            dolar={data.dolar_venta}  
                            flag={data.flag_variacion_venta} 
                            variacion={data.porc_variacion_compra} 
                            empresa={empresa} 
                            dataLine={venta_history} 
                            date={data.fecha_hora_cotizacion} 
                            onChangeCompany={onChangeCompany} 
                            type={'venta'} 
                          /> 
                        </div>

                        <div className="col-2 col-md-2 d-flex align-items-center justify-content-center">
                          <div className="d-grid">
                            <a href={"/pe/visitar/"+btoa(empresa.url_landing_page)+"/"+data.id_tracking_tce} className="btn btn-secondary d-none d-sm-block" target="_blank" atr-html-gtm-id={"link-"+empresa.cod_empresa}>
                              Visitar <i className="bi bi-chevron-right"></i>
                            </a>
                            <a href={"/pe/visitar/"+btoa(empresa.url_landing_page)+"/"+data.id_tracking_tce} className="btn btn-sm btn-secondary d-block d-sm-none" target="_blank" atr-html-gtm-id={"link-"+empresa.cod_empresa}>
                              <i className="bi bi-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                        
                      </div>
                  </div>
                
                </div>
        </div>
    );
}

const CardChartLine = ({dolar, flag, variacion, empresa,dataLine,date,onChangeCompany,type}) => {

        //amount
        function fnt_amount(number) {
          return empresa.simbolo_moneda + ' ' + number
        }

        //percentage
        function fnt_percentage(number) {
          return Math.abs(number).toFixed(2) + " %"
        }

        //color grafico
        const color_chart = {
          '1':  ["#2849CC"].join(" "),
          '0':  ["#2849CC"].join(" "), 
          '-1': ["#2849CC"].join(" ")
        }

        useEffect(() => {
          // init tooltip
          Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
            ).forEach(tooltipNode => new Tooltip(tooltipNode));
        }, []);
        
        return (
          <div className="row">
            <div className="col-12 col-md-7 d-flex align-items-center justify-content-center">
              <div className="row text-center d-flex align-items-center justify-content-center">
                <div className="col-12">
                  <p className="fs-4 mb-0 d-none d-sm-block">
                    {empresa.simbolo_moneda + dolar} 
                  </p>
                  <p className="fs-5 mb-0 d-block d-sm-none">
                    <small style={{fontSize: "0.8rem"}}>{empresa.simbolo_moneda}</small>{dolar} 
                  </p>
                </div>
                <div className="col d-none d-sm-block">
                  <IconDollar flag={flag} variacion={fnt_percentage(variacion)} />
                </div>
                <div role="button" className="col d-flex align-items-center justify-content-center d-block d-sm-none" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click para ampliar gráfico" onClick={onChangeCompany(empresa,type)} atr-html-gtm-id={"graph-"+type+"-"+ empresa.cod_empresa}>
                  <div className="col-12" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <Sparklines data={dataLine} height={60} margin={2} >
                      <SparklinesLine color={color_chart[flag]} style={{strokeWidth: 1.5}}/>
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
            <div role="button" className="col-5 col-md-5 d-flex align-items-center justify-content-center ps-0 my-auto d-none d-sm-block" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click para ampliar gráfico" onClick={onChangeCompany(empresa,type)} atr-html-gtm-id={"graph-"+type+"-"+ empresa.cod_empresa}>
              <div className="col-12" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <Sparklines data={dataLine} height={60} margin={2} >
                  <SparklinesLine color={color_chart[flag]} style={{strokeWidth: 1.5}}/>
                </Sparklines>
              </div>
            </div>
          </div>
        );
}

class IconDollar extends React.Component {
    
    render(){
      const iconType = {
        '1':  ["bi bi-arrow-up-short fs-6"].join(" "),
        '0':  ["bi bi-dash fs-6"].join(" "), 
        '-1': ["bi bi-arrow-down-short fs-6"].join(" ")
      }

      const spanType = {
        '1':  ["text-primary","bg-primary"].join(" "),
        '0':  ["text-primary","bg-primary"].join(" "), 
        '-1': ["text-primary","bg-primary"].join(" ")
      }

      const {flag, variacion} = this.props
      
      //<span className={"badge bg-opacity-10"+" "+spanType[flag]}>
      //  <small className="fst-normal">
      //    <i className={iconType[flag]}></i> {variacion}
      //  </small> 
      //</span>  

      return (
        <span className={"badge"} style={{backgroundColor: "#2849cc"+"19", color: "#2849cc"}}>
          <small className="fst-normal">
            <i className={iconType[flag]}></i> {variacion}
          </small> 
        </span>      
      );
    }
}

export default Card

//<div className="text-end text-muted d-none d-sm-block" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Última contización">
//  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
//    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
//    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
//  </svg>
//  <small className="ms-1">
//    {new Intl.DateTimeFormat('es-ES', options).format(new Date(data.fecha_hora_cotizacion))}
//  </small>
//</div>