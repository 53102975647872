import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'
import { getToken, getUsername, getEmail, removeUserSession } from './Utils/Common'


const Header = ({pais}) => {

	const [correo, setCorreo] = useState(getEmail);
	const [nombres, setNombres] = useState(getUsername);
	const [token, setToken] = useState(getToken);

	const onLogout = (key) => {
		removeUserSession();
	};

    useEffect(() => {
    	setCorreo(getEmail);
    	setNombres(getUsername);
    }, [correo, nombres]);

	return (
	<>
		<nav className="navbar navbar-expand-lg navbar-light bg-white py-0">
		  <div className="container">
			<a href={"/"+pais} className="navbar-brand">
			  <img src={ENV.DOLARTI_LOGO} width="auto" height="40" className="d-inline-block align-text-top"/>
			</a>
		    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		      <span className="navbar-toggler-icon"></span>
		    </button>
		    <div className="collapse navbar-collapse" id="navbarSupportedContent">
		      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
		        <li className="nav-item">

		        </li>
		      </ul>
		      <form className="d-flex flex-column flex-md-row">
		      {
		      	(token) ?
		      	<>
					<a className="btn btn-outline-light text-dark text-decoration-none my-1 my-md-auto me-md-3" href={"/"+pais+"/cuenta"}>
						Mi Cuenta
					</a>
					<button className="btn btn-outline-light text-dark text-decoration-none my-1 my-md-auto me-md-3" onClick={onLogout}>
						Cerrar sesión
					</button>
					<div className="btn-group">
						<a className="rounded-0 btn btn-link text-decoration-none bg-light text-dark text-opacity-75 py-2 px-3" data-bs-toggle="dropdown" aria-expanded="false">
							<div className="text-center text-md-start d-flex flex-column">
								<div className="fw-bold">Hola, {nombres}<i className="bi bi-caret-down-fill ms-2"></i></div>
								<small className="text-dark text-opacity-75">{correo}</small>
							</div>
						</a>
						<ul className="dropdown-menu">
						  <li><a className="dropdown-item" href={"/"+pais+"/cuenta"}>Mi Cuenta</a></li>
						  <li><button className="dropdown-item" href="#" onClick={onLogout}>Cerrar sesión</button></li>
						</ul>
					</div>
				</>
		      	:
		      	<>
		      		<hr className="my-2 d-block d-md-none"/>
					<a className="btn btn-link text-decoration-none text-nowrap m-0 me-md-3" href={"/"+pais+"/ingresar"} atr-html-gtm-id="ingresar">
						<i className="bi bi-person"></i> Ingresar
					</a>
					<hr className="my-2 d-block d-md-none"/>
			        <a className="btn btn-primary m-0" href={"/"+pais+"/registro"} atr-html-gtm-id="registrarse">Registrate</a>
		        </>
		      }
		      </form>
		    </div>
		  </div>
		</nav>
	</>
	)
}

export default Header

//<header className="bg-white bg-opacity-100 border-bottom">
//	<nav className="navbar navbar-light py-0">
//		<div className="container">
//			<a href={"/"+pais} className="navbar-brand">
//			  <img src={ENV.DOLARTI_LOGO} width="auto" height="40" className="d-inline-block align-text-top"/>
//			</a>
//		    <div>
//				{
//					(token) ?
//					<form className="d-flex align-items-center">
//						<a className="btn btn-outline-light text-dark text-decoration-none me-3" href={"/"+pais+"/cuenta"}>Mi Cuenta</a>
//					  	<button className="btn btn-outline-light text-dark text-decoration-none me-3" onClick={onLogout}>Cerrar sesión</button>
//					<div className="btn-group">
//						<a className="rounded-0 btn btn-link text-decoration-none bg-light text-dark text-opacity-75 py-2 px-3" data-bs-toggle="dropdown" aria-expanded="false">
//							<div className="text-start d-flex flex-column">
//								<div className="fw-bold">Hola, {nombres}</div>
//								<small className="text-dark text-opacity-75">{correo}</small>
//							</div>
//						</a>
//						<ul className="dropdown-menu">
//						  <li><a className="dropdown-item" href={"/"+pais+"/cuenta"}>Mi Cuenta</a></li>
//						  <li><button className="dropdown-item" href="#" onClick={onLogout}>Cerrar sesión</button></li>
//						</ul>
//					</div>
//					</form>
//					:
//					<form className="d-flex py-3">
//					  <a className="btn btn-link text-decoration-none text-nowrap me-3" href={"/"+pais+"/ingresar"}>
//					  	<i className="bi bi-person"></i> Ingresar
//					  </a>
//					  <a className="btn btn-primary" href={"/"+pais+"/registro"}>Registrate</a>
//					</form>
//				}
//			</div>
//		</div>
//	</nav>
//</header>